import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import cloneDeep from 'lodash/cloneDeep';

import AppContext from 'AppContext';
import { ReactComponent as CheckImg } from 'ressources/img/check.svg';
import { ReactComponent as InformationIcon } from 'ressources/img/information.svg';
import { OrderState, IOrder, LocalOrderState, WithdrawalTypeChoice } from 'types/order';
import { Currency, OfferPaymentMethod, OfferTemplateWithdrawalType } from 'types/graphql/globalTypes';
import {
    orderStateUpdates,
    getFormattedOrderId,
    moveOrderToLocalOrderList,
    revertMovingOrderToLocalOrderList,
} from 'services/orderService';
import { getDisplayDate, getDisplayTime } from 'services/dateService';
import { Select, Modal, PopIn } from 'components';
import { withStateMutation, stateMutationOptimisticResponse } from 'queries/mutations';
import Detail from './Detail';
import Action, { PrintAction } from './Action';
import { Button } from 'components/Button';
import { Text } from 'components/Printer/styles';
import { appTheme } from '../../styles/theme';
import { OFFER_TEMPLATES_TYPES } from './Card';
import { ReactComponent as EatInIcon } from 'ressources/img/in-site-gray.svg';
import { ReactComponent as TakeIcon } from 'ressources/img/take-away-gray.svg';

const getOneLocker = loader('../../queries/getOneLocker.graphql');

interface HeaderProps {
    readonly state: OrderState;
    readonly isLate?: boolean;
}

const headerHeight = 84;
const footerHeight = 130;
const paddingBottom = 50;
const lastUpdateHeight = 34;
const bannerHeight = 74;
const withdrawalTypeHeight = 200;
const withdrawalTypeHeightNA = 120;

const Header = styled.h3<HeaderProps>`
    height: ${headerHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${({ theme }) => theme.spacing.paddingL}px;
    background-color: ${({ theme, state, isLate }) =>
        theme.palette.background.order[isLate ? LocalOrderState.LATE : state]};
    flex-shrink: 0;
    color: ${({ theme, state }) => theme.palette.text.order[state]};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    text-transform: uppercase;
`;

const Close = styled.button`
    height: 100%;
    width: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
`;

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.paddingL}px;
    padding-bottom: ${paddingBottom}px;
    background-color: ${({ theme }) => theme.palette.background.light};
`;

const Footer = styled.footer`
    height: ${footerHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledAction = styled(Action)`
    margin-left: ${({ theme }) => theme.spacing.marginL}px;
`;

const LastUpdate = styled.div`
    display: flex;
    align-items: center;
    line-height: ${({ theme }) => theme.typography.fontSizeM}px;
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

const UpdatedAt = styled.span`
    height: ${lastUpdateHeight}px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing.margin}px;
    border-radius: 14px;
    background-color: ${({ theme }) => theme.palette.text.medium};
    padding: 0 ${({ theme }) => theme.spacing.unit * 2}px 0 ${({ theme }) => theme.spacing.unit}px;
    color: ${({ theme }) => theme.palette.text.light};
`;

const StyledCheckImg = styled(CheckImg)`
    height: ${({ theme }) => theme.typography.fontSizeM}px;
    width: ${({ theme }) => theme.typography.fontSizeM}px;
    margin-right: ${({ theme }) => theme.spacing.unit}px;
`;

const Message = styled.span`
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: 1.1em;
`;

const CleaningTimeLabel = styled.span`
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: 0.8em;
`;
const CleaningTime = styled.span`
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: 0.75em;
`;

const CleaningTimeContainer = styled.div`
    margin-top: 15px
`;

const Banner = styled.div`
    height: ${bannerHeight}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.marginL}px;
    margin-bottom: ${({ theme }) => theme.spacing.marginL * 2}px;
    padding: 0 ${({ theme }) => theme.spacing.padding}px;
    border-radius: 7px;
    box-shadow: 0 0 10px -4px rgba(65, 57, 107, 0.2);
    background-color: ${({ theme }) => theme.palette.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
`;
const Name = styled.div`
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
const Price = styled.div`
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
const Comment = styled.div`
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    color: ${({ theme }) => theme.palette.background.default};
    margin-top: ${({ theme }) => theme.spacing.unit}px;
`;
const CommentLabel = styled.div`
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    color: ${({ theme }) => theme.palette.text.medium};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;
const CommentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.marginL * 2}px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const TextWrapper = styled.div`
    text-align: center;
    margin-bottom: 40px;
`;

const ConfirmHeader = styled<any>(Text)`
    margin-bottom: 10px;
`;

const WithdrawalTypeRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const WithdrawalTypeHeader = styled.span`
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: 16px;
`;

const IconWrapper = styled.div`
    margin-right: 8px;
`;

interface ILockerDataWrapperProps {
    backgroundColor?: string;
    color?: string;
}
const LockerDataWrapper = styled.div<ILockerDataWrapperProps>`
    background-color: ${({ backgroundColor, theme }) =>
        backgroundColor ? backgroundColor : theme.palette.common.orange + theme.palette.opacity._10};
    margin: 5px 10px;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 17px;
    letter-spacing: 0;
    text-align: center;
    color: ${({ color, theme }) => (color ? color : theme.palette.grey[3])};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const WithdrawalMethodNAWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 60px;
`;

const OfferTypeLabel = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const OfferDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 45px;
`;

const Divider = styled.div`
    margin: 0 10px;
`;

interface IProps {
    isOpen: boolean;
    closeModal: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
    updateOrderState: Function;
    order: IOrder;
}

const OrderModal = ({ isOpen, updateOrderState, t, closeModal, order }: IProps & WithTranslation) => {
    const {
        home: { currentTab },
        setOrder,
        setOrders,
        orders: ordersList,
        setCountersAtTab,
        counters,
        offers,
        onCancellableUpdateStart,
    } = useContext(AppContext.Context);
    const offer = order && order.offer.offerTemplate;
    const [openConfirm, setOpenConfirm] = useState(false);
    const isConnectedLocker = offer?.withdrawalType === OfferTemplateWithdrawalType.CONNECTED_LOCKERS;
    const isSeatClickAndServe = offer?.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE;
    // @ts-ignore
    const isPayOnSite = order?.paymentMethod === OfferPaymentMethod.OnSite;

    const { loading, data } = useQuery(getOneLocker, {
        variables: {
            orderId: order && order.id,
        },
        skip: !isConnectedLocker || !order,
    });

    if (!order) return null;

    const {
        idWithdrawal,
        state,
        isLate,
        id,
        updated,
        guest: { firstName, lastName },
        // @ts-ignore
        totalPrice: { amount, currency },
        paymentMethod,
    } = order;
    const buttonStates = orderStateUpdates[state][1];
    const selectOptionStates = orderStateUpdates[state][2];
    const options = selectOptionStates
        ? selectOptionStates.map((state: any) => ({
              value: state,
              label: t(`schema:order.states.${state}`),
          }))
        : [];

    async function handleChange(newState: any) {
        const previousOrderState = { ...order };
        const optimisticOrderUpdated = {
            ...order,
            state: newState,
            updated: new Date(), //.toISOString()
        };

        const ordersListCopy = cloneDeep(ordersList);

        try {
            // to give faster feedback, assume the order will be updated with success and try to move it to the right tab right away
            moveOrderToLocalOrderList(
                currentTab,
                optimisticOrderUpdated,
                setOrder,
                ordersList,
                setOrders,
                setCountersAtTab,
                counters
            );

            await updateOrderState({
                variables: { idOrder: id, newState, paymentMethod },
                optimisticResponse: stateMutationOptimisticResponse(order, newState),
            });
        } catch (error) {
            console.log(error);
            // on error, revert the optimistically order update performed
            revertMovingOrderToLocalOrderList(
                optimisticOrderUpdated,
                previousOrderState,
                ordersListCopy,
                setOrders,
                setCountersAtTab,
                counters
            );
        }
    }

    const renderPayOnSiteInfo = () => {
        return (
            <LockerDataWrapper backgroundColor={appTheme.palette.common.green} color={appTheme.palette.text.light}>
                {t('page:home.orderModal.paymentOnSite').toUpperCase()}
            </LockerDataWrapper>
        );
    };

    const renderWithdrawalMethod = () => {
        if (!isConnectedLocker && !isSeatClickAndServe && !isPayOnSite) return null;

        const notAvailableMessage = (
            <WithdrawalMethodNAWrapper>
                <IconWrapper>
                    <InformationIcon />
                </IconWrapper>
                <WithdrawalTypeHeader>{t('page:home.orderModal.withdrawalTypesNA')}</WithdrawalTypeHeader>
            </WithdrawalMethodNAWrapper>
        );

        if (isSeatClickAndServe) {
            return (
                <>
                    <WithdrawalTypeHeader>
                        {t('page:home.orderModal.withdrawalTypes').toUpperCase()}
                    </WithdrawalTypeHeader>
                    <Banner>
                        <WithdrawalTypeRow>
                            <LockerDataWrapper
                                backgroundColor={appTheme.palette.common.green}
                                color={appTheme.palette.text.light}
                            >
                                {t('page:home.orderModal.tableNumber').toUpperCase()} {order.tableNumber}
                            </LockerDataWrapper>
                            {isPayOnSite && renderPayOnSiteInfo()}
                        </WithdrawalTypeRow>
                    </Banner>
                </>
            );
        }

        if (isConnectedLocker) {
            if (order.state === OrderState.ACCEPTED || order.state === OrderState.PREPARING) {
                return notAvailableMessage;
            }

            if (loading || !data || !data.getOneLocker) {
                return notAvailableMessage;
            }

            const timeSlotRange = `${getDisplayTime(new Date(data.getOneLocker.startDate))} - ${getDisplayTime(
                new Date(data.getOneLocker.endDate)
            )}`;

            return (
                <>
                    <WithdrawalTypeHeader>
                        {t('page:home.orderModal.withdrawalTypes').toUpperCase()}
                    </WithdrawalTypeHeader>
                    <Banner>
                        <WithdrawalTypeRow>
                            <LockerDataWrapper
                                backgroundColor={appTheme.palette.common.blue}
                                color={appTheme.palette.text.light}
                            >
                                {timeSlotRange}
                            </LockerDataWrapper>
                            <LockerDataWrapper>{data.getOneLocker.machine.name}</LockerDataWrapper>
                            <LockerDataWrapper>{`#${data.getOneLocker.idLocker}`}</LockerDataWrapper>
                            <LockerDataWrapper>{data.getOneLocker.code}</LockerDataWrapper>
                        </WithdrawalTypeRow>
                    </Banner>
                </>
            );
        }

        if (isPayOnSite) {
            return (
                <>
                    <WithdrawalTypeHeader>
                        {t('page:home.orderModal.withdrawalTypes').toUpperCase()}
                    </WithdrawalTypeHeader>
                    <Banner>
                        <WithdrawalTypeRow>{renderPayOnSiteInfo()}</WithdrawalTypeRow>
                    </Banner>
                </>
            );
        }
    };

    const stateMessage =
        order.state === OrderState.ACCEPTED
            ? isPayOnSite
                ? t(`page:home.orderModal.stateInfo.${state}_PayOnSite`)
                : t(`page:home.orderModal.stateInfo.${state}`)
            : t(`page:home.orderModal.stateInfo.${state}`);

    const offerType = () => {
        const isOnSite = order.withdrawalType
            ? order.withdrawalType === WithdrawalTypeChoice.ON_SITE
            : offer?.withdrawalType && OFFER_TEMPLATES_TYPES.EAT_IN.includes(offer.withdrawalType);
        return (
            <>
                {isOnSite ? (
                    <OfferTypeLabel>
                        <EatInIcon
                            style={{
                                marginRight: 5,
                            }}
                        />
                        <span>{t('page:home.orderCard.eatIn')}</span>
                    </OfferTypeLabel>
                ) : (
                    <OfferTypeLabel>
                        <TakeIcon
                            style={{
                                marginRight: 5,
                            }}
                        />
                        <span>{t('page:home.orderCard.takeAway')}</span>
                    </OfferTypeLabel>
                )}
            </>
        );
    };

    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <PopIn isOpen={openConfirm}>
                <TextWrapper>
                    <ConfirmHeader bold size="XL">
                        {t('page:home.cancelConfirm.header')}
                    </ConfirmHeader>
                    <Text size="L">{t('page:home.cancelConfirm.content')}</Text>
                </TextWrapper>
                <ButtonWrapper>
                    <Button color="error" onClick={() => setOpenConfirm(false)}>
                        <Text color="light">{t('page:home.cancelConfirm.actions.ko')}</Text>
                    </Button>
                    <Button
                        color="confirm"
                        onClick={() => {
                            setOpenConfirm(false);
                            handleChange(OrderState.REFUSED);
                        }}
                    >
                        <Text color="light">{t('page:home.cancelConfirm.actions.ok')}</Text>
                    </Button>
                </ButtonWrapper>
            </PopIn>
            {
                // @ts-ignore
                <Header state={state} isLate={isLate}>
                    <span>{t('page:home.orderModal.header', { idWithdrawal })}</span>
                    {offer && offer.withdrawalType === OfferTemplateWithdrawalType.CONNECTED_LOCKERS ? (
                        <span> ID: {getFormattedOrderId(id)}</span>
                    ) : null}
                    <Close onClick={closeModal}>&times;</Close>
                </Header>
            }
            <Wrapper>
                <LastUpdate>
                    {updated && (
                        <UpdatedAt>
                            <StyledCheckImg />
                            <span>{`${getDisplayTime(updated)} le ${getDisplayDate(updated)}`}</span>
                        </UpdatedAt>
                    )}
                    <Message>{stateMessage}</Message>
                </LastUpdate>
                <CleaningTimeContainer>
                    {order.cleaningTime && <div>
                        <CleaningTimeLabel>{t(`page:home.orderCard.roomService.cleaningTimeLabel`)}</CleaningTimeLabel>
                        <CleaningTime> : {getDisplayTime(new Date(order.cleaningTime))}</CleaningTime>
                    </div>}
                </CleaningTimeContainer>
                <Banner>
                    <Name>{`${firstName} ${lastName}`}</Name>
                    <Price>{`${amount} ${currency === Currency.EUR ? '€' : currency}`}</Price>
                </Banner>
                {((offers && offers.length > 1) || order.withdrawalType) && (
                    <OfferDetails>
                        <span>{offer.name}</span>
                        <Divider>{'|'}</Divider>
                        {offerType()}
                        <Divider>{'|'}</Divider>
                        <span>{offer.pos.name}</span>
                    </OfferDetails>
                )}
                {renderWithdrawalMethod()}
                {order.comment && (
                    <CommentWrapper>
                        <CommentLabel>{t(`page:home.orderModal.comment`)}</CommentLabel>
                        <Comment>{order.comment}</Comment>
                    </CommentWrapper>
                )}
                <Detail order={order} />
                <Footer>
                    {selectOptionStates && (
                        <Select
                            isSearchable={false}
                            options={options}
                            valuePrefix={`${t('schema:order.state')} :`}
                            selectedOption={options.find((o) => o.value === state)}
                            handleChange={(selectedOption: any) => handleChange(selectedOption.value)}
                            style={{
                                valueContainer: {
                                    display: 'block'
                                }
                            }}
                        />
                    )}
                    <ButtonWrapper>
                        {order.state !== OrderState.ACCEPTED && <PrintAction order={order} />}
                        {buttonStates &&
                            buttonStates.map((state) => (
                                <StyledAction
                                    key={state}
                                    immediate
                                    order={order}
                                    newState={state}
                                    onCancelled={() => {
                                        setOpenConfirm(true);
                                    }}
                                />
                            ))}
                    </ButtonWrapper>
                </Footer>
            </Wrapper>
        </Modal>
    );
};

export default withTranslation()(withStateMutation(OrderModal));
